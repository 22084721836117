const routes = [
  {
    path: ["/", "/home", "*"],
    exact: true,
    component: "Home",
  },
  //if you want to add the 404 page, just uncomment the following lines and remove the * from line 3
  // {
  //   path: "*",
  //   exact: false,
  //   component: "NotFound",
  // }
];

export default routes;
