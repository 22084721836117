import { Row, Col } from "antd";
import Container from "../../common/Container";

import {
  FooterSection,
  Large,
  Language,
  Byline,
  BylineSpan,
} from "./styles";

const Footer = () => {

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>Give us a call</Language>
              <Large>(407) 205-2010</Large>
              <Language>Drop us a line</Language>
              <a target={'_blank'} rel={'noreferrer'} href={"mailto:info@andythebuilder.com"}><Large>info@andythebuilder.com</Large></a>
            </Col>
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>Certified Building Contractor</Language>
              <a href={"https://www.myfloridalicense.com/LicenseDetail.asp?SID=&id=90BBB7EEFFAB64F4293587887D011F2B"} target={"_blank"} rel={"noreferrer"}><Large>CBC 1263339</Large></a>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
                <Byline>Designed and developed by <a href={"https://cluzier.github.io/"} target={"_blank"} rel={"noreferrer"}><BylineSpan>Conner Luzier</BylineSpan></a> &copy; 2021</Byline>
            </Col>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default Footer;
