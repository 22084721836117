import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import { ParallaxProvider } from 'react-scroll-parallax';

import Router from "./router";

const App = () => (
  <ParallaxProvider>
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </ParallaxProvider>
  
);

ReactDOM.render(<App />, document.getElementById("root"));
